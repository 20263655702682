const idPortalApi = "18af8731cc529374fa2186e59feca68657b9ecd4";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmgovernadornewtonbello.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmgovernadornewtonbello.ma.gov.br",
  NomePortal: "Governador Newton Bello",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_instagram: `https://www.instagram.com/cmgnbma`,
  link_localize: `https://maps.app.goo.gl/JvtoME67AUEU3qSs6`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3982.6908399865865!2d-45.6698026!3d-3.4252616999999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92b59f3161c91651%3A0x3562a23652308196!2sCamara%20Municipal%20de%20Governador%20Newton%20Bello!5e0!3m2!1spt-BR!2sbr!4v1736879595538!5m2!1spt-BR!2sbr",
};
